import React, { FunctionComponent } from "react"
import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar } from "@material-ui/core"
import CheckIcon from '@material-ui/icons/Check'
import BusinessIcon from "@material-ui/icons/Business"
import FaceIcon from "@material-ui/icons/Face"
import { useStyles } from "../../styles/useStyle"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

export const PageDrawer: FunctionComponent = () => {
  const location = useLocation()
  const classes = useStyles()
  return (
    <Drawer variant={"permanent"} className={classes.drawer} classes={{ paper: classes.drawerPaper }}>
      <Toolbar />
      <List>
        <Link to={"/app/medical"} style={{ textDecoration: "none", color: "inherit" }}>
          <ListItem button selected={location.pathname === "/app/medical"}>
            <ListItemIcon><BusinessIcon/></ListItemIcon>
            <ListItemText>医療機関登録</ListItemText>
          </ListItem>
        </Link>
        <Link to={"/app/user"} style={{ textDecoration: "none", color: "inherit" }}>
          <ListItem button selected={location.pathname === "/app/user"}>
            <ListItemIcon><FaceIcon /></ListItemIcon>
            <ListItemText>ユーザー登録</ListItemText>
          </ListItem>
        </Link>
        <Divider />
        <Link to={"/app/medical_department"} style={{ textDecoration: "none", color: "inherit" }}>
          <ListItem button selected={location.pathname === "/app/medical_department"}>
            <ListItemIcon><CheckIcon/></ListItemIcon>
            <ListItemText>医療機関出力</ListItemText>
          </ListItem>
        </Link>
      </List>
    </Drawer>
  )
}
